import { ErrCapture } from "@/components/ErrCapture"
import { ThemeContextProvider } from "@/contexts/ThemeContext"
import { client } from "@/graphql/index.graphql"
import { ApolloProvider } from "@apollo/client"
import React from "react"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import "./App.less"
import { Decommission } from "../Decommission"

// need to put more specific match first then the more general match

const App = (): JSX.Element => {
  return (
    <ErrCapture>
      <ApolloProvider client={client}>
        <ThemeContextProvider>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={Decommission} />
              <Route path="*" render={() => <Redirect to="/" />} />
            </Switch>
          </BrowserRouter>
        </ThemeContextProvider>
      </ApolloProvider>
    </ErrCapture>
  )
}

export default App
