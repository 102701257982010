/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, Component } from "react"
import { Button } from "antd"
import { ReloadOutlined } from "@ant-design/icons"
import Rollbar from "rollbar"
import imgURL from "./err-img.jpg"
import "./ErrCapture.less"

interface Props {
  children: ReactNode
}
interface State {
  hasError: boolean
  rollbar: Rollbar
}

export class ErrCapture extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      hasError: false,
      rollbar: new Rollbar({
        accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment: process.env.REACT_APP_ENVIRONMENT
        }
      })
    }
  }

  static getDerivedStateFromError(error: Error): { hasError: boolean } {
    // 更新 state 使下一次渲染可以显示降级 UI
    return { hasError: true }
  }

  componentDidCatch(error: Error, info: any): void {
    console.error(info.componentStack)
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <div className="phc-error-module">
          <div>
            <img src={imgURL} alt="" />
            <h2>Oops~PH seems to have something wrong</h2>
            <Button
              onClick={(): void => {
                window.location.href = "/"
              }}
            >
              Refresh
              <ReloadOutlined />
            </Button>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}
