/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, ReactNode, useState } from "react"
import { MenuTheme } from "antd/lib/menu/MenuContext"
type Theme = {
  theme: MenuTheme
  toggleTheme: Function
}
const initTheme = "light"

export const ThemeContext = createContext<Theme>({
  theme: initTheme,
  toggleTheme: () => {}
})

export const ThemeContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [theme, setTheme] = useState<MenuTheme>(initTheme)
  function toggleTheme(): void {
    if (theme === "dark") {
      setTheme("light")
    } else {
      setTheme("dark")
    }
  }
  return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>
}
