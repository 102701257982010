import React from "react"
import { Tag } from "antd"
import "./Loading.less"

export const Loading = ({
  error,
  pastDelay
}: {
  error?: string
  pastDelay?: boolean
}): JSX.Element => {
  if (error) {
    return (
      <div className="phc-err-box">
        <Tag color="#f50">{error || "Error!"}</Tag>
      </div>
    )
  } else if (pastDelay) {
    return (
      <div className="phc-spinner" data-testid="loading">
        <div className="phc-bounce phc-bounce1"></div>
        <div className="phc-bounce phc-bounce2"></div>
        <div className="phc-bounce phc-bounce3"></div>
      </div>
    )
  } else {
    return (
      <div className="phc-spinner" data-testid="loading">
        <div className="phc-bounce phc-bounce1"></div>
        <div className="phc-bounce phc-bounce2"></div>
        <div className="phc-bounce phc-bounce3"></div>
      </div>
    )
  }
}

export default Loading
