import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client"
import { onError } from "@apollo/client/link/error"

const httpLink = new HttpLink({
  uri: "/api/graphql"
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      // TODO: in the future we need to improve this condition
      const graphQLErrorsConditions = [
        "creation failed: must authenticate",
        "Invalid Username Or Password",
        "Not authenticated"
      ]
      if (graphQLErrorsConditions.some((condition) => message.includes(condition))) {
        const origin = window.location.origin
        const noRedirectUrlConditions = ["redirect", "requestPassword", "resetPassword", "login"]

        if (noRedirectUrlConditions.some((condition) => window.location.href.includes(condition))) {
          return
        }

        window.location.href = `${origin}/login?redirect=${window.location.href}`
      }
    })
  }

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const client = new ApolloClient({
  link: errorLink.concat(httpLink),
  cache: new InMemoryCache()
})
export { client }
